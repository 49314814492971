// JsFromRoutes CacheKey 3b6fc45437a88359ce15f4eb15d970c5
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/sign_in'),
  create: definePathHelper('post', '/users/sign_in'),
  destroy: definePathHelper('delete', '/users/sign_out'),
  handleOtpStep: definePathHelper('post', '/users/auth/handle_otp_step'),
  handleSplashscreenStep: definePathHelper('post', '/users/auth/handle_splashscreen_step'),
  validateSso: definePathHelper('post', '/users/auth/validate_sso'),
  refreshCsrf: definePathHelper('get', '/users/refresh_csrf'),
  isUserLogged: definePathHelper('post', '/users/is_user_logged'),
}
